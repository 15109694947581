import './article-block.scss'

import React from 'react'
import ReactMarkdown from 'react-markdown'

interface ArticleBlockProps {
    blok: {
        title: string
        intro: string
        asset: { filename: string }
        content: string
    }
}

const ArticleBlock = ({ blok }: ArticleBlockProps) => {
    const { title, intro, asset, content } = blok
    return (
        <div className="DocBlock">
            <h2>{title}</h2>

            <div className="DocBlock__intro">{intro}</div>

            {['jpg', 'gif', 'png', 'jpeg'].indexOf(asset?.filename?.slice(-3)) >= 0 && (
                <img className="DocBlock__asset" src={asset.filename} alt="" />
            )}

            {['mov', 'avi', 'mp4', 'wmv'].indexOf(asset?.filename?.slice(-3)) >= 0 && (
                <video loop muted autoPlay className="DocBlock__asset" src={asset.filename} />
            )}

            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    )
}

export default ArticleBlock
