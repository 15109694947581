import './layout.scss'
import 'typeface-roboto'

import React from 'react'
import { Helmet } from 'react-helmet'

import StoryblokService from '../../utils/storyblok-service'
import Footer from '../footer/footer'
import Header from '../header/header'

interface LayoutProps {
    children: JSX.Element | JSX.Element[] | any
    mainPages: {
        slug: string
        name: string
    }[]
}

const Layout: React.FC<LayoutProps> = ({ children, mainPages }: LayoutProps) => {
    return (
        <>
            <Helmet
                script={[
                    {
                        src: `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`,
                        type: 'text/javascript',
                    },
                ]}
            />
            <Helmet
                script={[
                    {
                        innerHTML: `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
                        type: 'text/javascript',
                    },
                ]}
            />
            <div className="Layout">
                <Header mainPages={mainPages} />

                <main>{children}</main>

                <Footer id="footer"></Footer>
            </div>
        </>
    )
}

export default Layout
