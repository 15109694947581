import './image-teaser-container.scss'
import * as React from 'react'
import ImageTeaserHorizontal from '../imageteaser-horizontal/imageTeaser-horizontal'

export default class ImageTeaserContainer extends React.Component<IImageTeaserProps> {
    public render() {
        const { imageTeasers } = this.props.blok

        return (
            <div className="ImageTeaserContainer">
                {imageTeasers.map((teaser, i) => (
                    <ImageTeaserHorizontal key={i} blok={teaser}></ImageTeaserHorizontal>
                ))}
            </div>
        )
    }
}
