import './intro.scss'

import * as React from 'react'

export interface IIntroProps {
    blok: {
        title: string
        text: string
        background: 'grey' | 'white'
    }
}

export default class Intro extends React.Component<IIntroProps> {
    public render() {
        const { title, text, background } = this.props.blok

        return (
            <div className={`Intro Intro--${background || 'grey'}`}>
                <h2 className="Intro__title">{title}</h2>
                <div className="Intro__text">{text}</div>
            </div>
        )
    }
}
