import './imageteaser.scss'

import { navigate } from 'gatsby'
import * as React from 'react'

import Avatar from '../avatar/avatar'
import Button from '../button/button'
import cc from 'classcat'
import YoutubeFrame from '../youtubeFrame/youtubeFrame'
import { LinkBlok } from '../../types/linkBlok'

export interface IImageTeaserProps {
    blok: {
        headline: string
        text: string
        cta_text: string
        cta_link: LinkBlok
        avatar_image: { filename: string }
        avatar_name: string
        avatar_jobtitle: string
        asset: { filename: string }
        image_left: boolean
        youtube_video: LinkBlok
    }
}

export default class ImageTeaser extends React.Component<IImageTeaserProps> {
    navigateTo = (cta_link: any) => {
        const url = (cta_link.linktype === 'url' ? '' : '/') + cta_link.cached_url
        navigate(url)
    }

    public render() {
        const {
            headline,
            text,
            cta_text,
            cta_link,
            avatar_image,
            avatar_name,
            avatar_jobtitle,
            asset,
            image_left,
            youtube_video,
        } = this.props.blok

        return (
            <div className={cc({ ImageTeaser: true, 'ImageTeaser--imageLeft': image_left })}>
                <div className="ImageTeaser__content">
                    <h1 className="ImageTeaser__title">{headline}</h1>

                    <p>{text}</p>

                    {avatar_image && avatar_name && (
                        <Avatar image={avatar_image.filename} name={avatar_name} jobtitle={avatar_jobtitle} />
                    )}

                    {cta_link && cta_text && (
                        <Button variant="primary" handleClick={() => this.navigateTo(cta_link)}>
                            {cta_text}
                        </Button>
                    )}
                </div>

                {['jpg', 'png'].includes(asset?.filename?.slice(-3)) && (
                    <img className="ImageTeaser__asset" src={asset.filename} alt="" />
                )}

                {asset?.filename?.slice(-3) === 'mov' && (
                    <video loop muted autoPlay className="ImageTeaser__asset" src={asset.filename} />
                )}
                {youtube_video?.cached_url && <YoutubeFrame link={youtube_video.cached_url} />}
            </div>
        )
    }
}
