import './showcase.scss'

import React from 'react'

import Card, { CardProps } from '../card/card'
import type { ArticleCardProps } from '../article-card/article-card'
import ArticleCard from '../article-card/article-card'

interface ShowcaseProps {
    blok: {
        subtitle: string
        title: string
        cards: CardProps[]
        articleCards: ArticleCardProps[]
    }
}

const Showcase = ({ blok }: ShowcaseProps) => {
    return (
        <div className="Showcase">
            <div className="Showcase__title-wrap">
                <p className="Showcase__title">{blok.subtitle}</p>
                <h3 className="Showcase__second_title">{blok.title}</h3>
            </div>

            <div className="Showcase__container">
                {blok.cards && blok.cards && blok.cards.map(card => <Card key={card.name} {...card} />)}
                {blok.articleCards && blok.articleCards.map(card => <ArticleCard key={card.name} {...card} />)}
            </div>
        </div>
    )
}

export default Showcase
