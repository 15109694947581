import './button.scss'

import React from 'react'
import cc from 'classcat'

interface IButton {
    children?: any
    variant: 'primary'
    handleClick?: (event: React.MouseEvent) => any
}

const Button = ({ children, variant, handleClick }: IButton) => (
    <button
        onClick={event => handleClick && handleClick(event)}
        className={cc({ Button: true, 'Button--primary': variant === 'primary' })}
    >
        {children}
    </button>
)

export default Button
