import './avatar.scss'

import React from 'react'

interface AvatarProps {
    name: string
    image: string
    jobtitle: string
}

const Avatar = ({ name, image, jobtitle }: AvatarProps) => (
    <div className="Avatar">
        <img className="Avatar__image" src={image} alt={name} />
        <div className="Avatar__name">{name}</div>
        <div className="Avatar__jobtitle">{jobtitle}</div>
    </div>
)

export default Avatar
