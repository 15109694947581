import React from 'react'

import { Blok } from '../types/blok'
import Card from './card/card'
import DocBlock from './docblock/docblock'
import Grid from './grid/grid'
import ImageTeaser from './imageteaser/imageteaser'
import Intro from './intro/intro'
import Logowall from './logowall/logowall'
import Notice from './notice/notice'
import Showcase from './showcase/showcase'
import Statistics from './statistics/statistics'
import Contact from './contact/contact'
import Teaser from './teaser/teaser'
import TestimonyCard from './testimonycard/testimonyCard'
import Testimony from './testimony/testimony'
import Integration from './integration/integration'
import ArticleCard from './article-card/article-card'
import ArticleBlock from './ article-block/article-block'
import BackgroundImage from './background-image/background-image'
import ImageTeaserV2 from './image-teaser-v2/imageteaserV2'
import ImageTeaserHorizontal from './imageteaser-horizontal/imageTeaser-horizontal'
import ImageTeaserContainer from './image-teaser-container/image-teaser-container'

const Components: { [key: string]: any } = {
    docblock: DocBlock,
    docblocksmall: DocBlock,
    intro: Intro,
    teaser: Teaser,
    grid: Grid,
    notice: Notice,
    hint: Notice,
    imageteaser: ImageTeaser,
    multiImageTeaser: ImageTeaserV2,
    logowall: Logowall,
    card: Card,
    showcase: Showcase,
    testimony: Testimony,
    testimonycard: TestimonyCard,
    statistics: Statistics,
    contact: Contact,
    integration: Integration,
    articleBlock: ArticleBlock,
    backgroundImage: BackgroundImage,
    horizontalImageTeaser: ImageTeaserHorizontal,
    imageTeaserContainer: ImageTeaserContainer,
}

type DynamicComponentType = {
    blok: Blok
}

const DynamicComponent = ({ blok }: DynamicComponentType) => {
    if (typeof Components[blok.component] !== 'undefined') {
        const Comp = Components[blok.component]
        return <Comp blok={blok} />
    }

    return <div style={{ backgroundColor: 'red', color: '#fff' }}>{blok.component}</div>
}

export default DynamicComponent
