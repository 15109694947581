import React from 'react'
import './integrationIcon.scss'

const IntegrationIcon = ({ icon }: { filename: string }) => {
    return (
        <div className="IntegrationIcon">
            <img src={icon.icon.filename} className="IntegrationIcon__icon" />
        </div>
    )
}

export default IntegrationIcon
