import { graphql } from 'gatsby'
import React from 'react'

import Page from '../components/page/page'
import StoryblokService from '../utils/storyblok-service'

export default class IndexPage extends React.Component {
    state = {
        story: {
            content: this.props.data.story ? JSON.parse(this.props.data.story.content) : {},
        },
    }

    async getInitialStory() {
        StoryblokService.setQuery(this.props.location.search)
        const {
            data: { story },
        } = await StoryblokService.get(`cdn/stories/${this.props.data?.story?.full_slug}`)
        return story
    }

    async componentDidMount() {
        const story = await this.getInitialStory()
        if (story.content) this.setState({ story })
        setTimeout(() => StoryblokService.initEditor(this), 200)
    }

    render() {
        const documentationPages = this.props.pageContext.documentationPages
            .filter(n => !n.full_slug.search('doku/anleitungen'))
            .map(n => {
                return {
                    name: n.name,
                    slug: n.path ? n.path : n.slug,
                    full_slug: n.full_slug,
                }
            })
        return (
            <Page
                name={this.props.data.story?.name}
                blok={this.state.story.content}
                documentationPages={documentationPages}
                mainPages={this.props.pageContext.mainPages}
            />
        )
    }
}

export const query = graphql`
    query ($slug: String!) {
        story: storyblokEntry(full_slug: { eq: $slug }) {
            id
            name
            content
            full_slug
            uuid
        }
    }
`
