import './pagetree.scss'

import cc from 'classcat'
import { Link } from 'gatsby'
import * as React from 'react'
import Chevron from '../../images/icons/chevron.svg'

type ITreeLeaf = {
    name: string
    slug: string
    children: ITreeLeaf[]
    full_slug?: string
}

export interface IPageTreeProps {
    pages: any
}

export default class PageTree extends React.Component<IPageTreeProps> {
    public render() {
        const { pages } = this.props

        const pagetree = pages.reduce((r, p) => {
            const names = p.full_slug.split('/')
            names.reduce((q, name) => {
                let temp = q.find(o => o.name === name)
                if (!temp) {
                    const leaf = { name, children: [] }
                    if (p.full_slug.slice(0 - leaf.name.length) === leaf.name) {
                        leaf.full_slug = p.full_slug
                        leaf.title = p.name
                    }
                    q.push((temp = leaf))
                }

                return temp.children
            }, r)
            return r
        }, [])

        return (
            <div className="PageTree">
                <TreeLeaf tree={pagetree} />
            </div>
        )
    }
}

const TreeLeaf = ({ tree }: any): JSX.Element => {
    return (
        <div className="TreeLeaf">
            {tree.map((branch: ITreeLeaf, i: number) => (
                <React.Fragment key={i}>
                    {!branch.full_slug && <TreeLeafFolder branch={branch} />}

                    {branch.full_slug && (
                        <div className="TreeLeaf__link">
                            <Link to={`/${branch.full_slug}`}>{branch.title}</Link>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}

const TreeLeafFolder = ({ branch }: ITreeLeaf): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <div className={cc({ TreeLeaf__folder: true, 'TreeLeaf__folder--open': isOpen })}>
            <div
                className="TreeLeaf__folder__name"
                onClick={event => {
                    event.stopPropagation()
                    setIsOpen(!isOpen)
                }}
            >
                <h3>
                    {branch.name}{' '}
                    <img
                        src={Chevron}
                        alt="folder chevron"
                        className={cc({ TreeLeaf__folder__icon: true, 'TreeLeaf__folder__icon--open': isOpen })}
                    />
                </h3>
            </div>

            {branch.children && (
                <div className="TreeLeaf__folder__children" style={{ paddingLeft: 16 }}>
                    <TreeLeaf tree={branch.children} />
                </div>
            )}
        </div>
    )
}
