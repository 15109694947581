import React from 'react'
import './card.scss'
import ArrowRightIcon from './../../images/arrow_right.svg'

export interface CardProps {
    image: { filename: string }
    name: string
    location: string
    usecases: {
        icon: { filename: string }
        text: string
    }[]
    link_text?: string
    link_target?: string
}

const Card = ({ image, name, location, usecases, link_text, link_target }: CardProps) => (
    <div className="Card">
        <a href={link_target} target="_blank" rel="noreferrer">
            <div className="Card__image__container">
                <img className="Card__image" src={image.filename} alt="" />
                <div className="overlay"></div>
                <div className="Card__image__text--centered ">
                    <h3 className="Card__content__name ">{name}</h3>
                    <h3 className="Card__content__name Card__content__location "> {location}</h3>
                </div>
            </div>
        </a>
        <div className="Card__content">
            <div className="Card__usecases">
                {usecases.map(usecase => (
                    <div className="Card__usecases__usecase">
                        <img className=" Card__icon" src={usecase.icon.filename} alt="" />
                        <div className="Card__usecase__text">{usecase.text}</div>
                    </div>
                ))}
            </div>
        </div>
    </div>
)

export default Card
