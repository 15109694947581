import React from 'react'
import './integration.scss'
import IntegrationIcon from '../integrationIcon/integrationIcon'
import ArrowRightIcon from '../../images/arrow_right.svg'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

interface IntegrationProps {
    blok: {
        title: string
        text: string
        rowNumber: number
        icons: { filename: string }[]
    }
}

const Integration = ({ blok }: IntegrationProps) => {
    return (
        <div className="Integration">
            <div className="Integration__content">
                <div className="Integration__title">{blok.title}</div>
                <div className="Integration__text">{blok.text}</div>
            </div>
            <div className="Integration__container">
                {blok.icons.map((icon, index) => (
                    <IntegrationIcon icon={icon} key={index} />
                ))}
            </div>
            <Link className="Integration__link" to="/integrationen">
                Alle Integrationen anzeigen
            </Link>
        </div>
    )
}
export default Integration
