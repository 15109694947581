import React from 'react'
import './contactLink.scss'

const ContactLink = ({href , icon , text}) => {
    return (
                    <a className="ContactLink" href={href}>
                        <img className="ContactLink__icon" src={icon} alt="email icon" />
                        <div className="ContactLink__text">{text}</div>
                    </a>
    )
}

export default ContactLink
