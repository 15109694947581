import './footer.scss'

import { Link } from 'gatsby'
import React from 'react'

import MailIcon from '../../images/icons/mail_icon.svg'
import PhoneIcon from '../../images/icons/phone_icon.svg'
import logo from '../../../public/icons/estateos_black.svg'
import ContactLink from './components/contactLink'

const Footer = () => {
    return (
        <footer className="Footer">
            <div className="Footer__content">
                <img className="Footer__content__icon" src={logo} />

                <div className="Footer__content__description">
                    Hier kann ein kurzer freundlicher Text stehen. Hier kann ein kurzer freundlicher Text stehen.{' '}
                </div>

                <div>
                    <ContactLink href="mailto: info@estateos.com" icon={MailIcon} text="info@estateos.com" />
                    <ContactLink href="tel:+498932499529" icon={PhoneIcon} text="+ 49 89 324 99 529" />
                </div>
            </div>

            <div className="Footer__content Footer__content--mobile">
                <div className="Footer__content__title"> About Us</div>

                <div className="Footer__content__links">
                    <Link to="/about-us/impressum" className="Footer__content__links__link">
                        Impressum
                    </Link>

                    <Link to="/about-us/estateos-datenschutz" className="Footer__content__links__link">
                        Datenschutz
                    </Link>

                    <Link to="/about-us/living25-support" className="Footer__content__links__link">
                        Living25 Support
                    </Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer
