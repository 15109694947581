import React from 'react'
import './testimonycard.scss'

export interface TestimonyCardProps {
    image: { filename: string }
    name: string
    company: string
    title: string
    testimony: string
}

const TestimonyCard = ({ image, name, company, title, testimony }: TestimonyCardProps) => (
    <div className="TestimonyCard">
        <img className="TestimonyCard__image" src={image.filename} alt="" />

        {name && company && (
            <>
                <h3 className="Card__name">{name}</h3>

                <h3 className="Card__company"> {location}</h3>
            </>
        )}

        <div className="TestimonyCard__content">
            <div className="TestimonyCard__title">{title}</div>

            <div className="TestimonyCard__paragraph">{testimony}</div>
        </div>
    </div>
)

export default TestimonyCard
