import './youtubeFrame.scss'

import React from 'react'

interface YoutubeFrameProps {
    link: string
}

const YoutubeFrame = ({ link }: YoutubeFrameProps) => {
    return (
        <iframe
            className="YoutubeVideo"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            scrolling="no"
            allowFullScreen={true}
            src={link}
        />
    )
}

export default YoutubeFrame
