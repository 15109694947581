import React, { useState } from 'react'
import './contact.scss'
import cc from 'classcat'
import Link from 'gatsby-link'

const Contact = () => {
    const [state, setState] = useState({
        name: '',
        email: '',
        message: '',
        acceptRules: false,
    })
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [infoMsg, setInfoMsg] = useState('')
    const [isSpam, setIsSpam] = useState(false)

    const handleSubmit = evt => {
        evt.preventDefault()
        if (isSpam) return
        setLoading(true)
        setTimeout(() => {
            setSuccess(true)
            setInfoMsg('Thank you! Your submission has been received!')
        }, 1000)
    }
    const handleInputChange = evt => {
        setState({ ...state, [evt.target.name]: evt.target.value })
    }

    return (
        <section id="contact-form" className="Contact">
            <div className="w-container">
                <h2 className="Contact__title">Let's talk</h2>

                <p className="Contact__description">
                    If you are interested in getting to know the software better or have any other question, please feel
                    free to contact us. We will get back to you as soon as possible.
                </p>
                <div className="Contact__form">
                    {success === null ? (
                        <form onSubmit={handleSubmit}>
                            <div className="Contact__form__form-field">
                                <label htmlFor="name" className="Contact__form__form-field__label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="Contact__form__form-field__input w-input"
                                    maxLength="256"
                                    name="name"
                                    data-name="Name"
                                    placeholder=""
                                    id="name"
                                    required
                                    onChange={e => handleInputChange(e)}
                                />
                            </div>
                            <div className="Contact__form__form-field">
                                <label htmlFor="email" className="Contact__form__form-field__label">
                                    E-Mail
                                </label>
                                <input
                                    type="email"
                                    className="Contact__form__form-field__input w-input"
                                    maxLength="256"
                                    name="email"
                                    data-name="email"
                                    placeholder=""
                                    id="email"
                                    required
                                    onChange={e => handleInputChange(e)}
                                />
                            </div>
                            <div className="Contact__form__form-field">
                                <label htmlFor="message" className="Contact__form__form-field__label">
                                    Deine Nachricht
                                </label>
                                <textarea
                                    name="message"
                                    maxLength="5000"
                                    id="message"
                                    required
                                    className="Contact__form__form-field__input TextAria "
                                    onChange={e => handleInputChange(e)}
                                />
                                <div className="Contact__form__data_protection_check">
                                    <input type="checkbox" name="acceptRules" autoComplete="off" required />
                                    <a
                                        href="/about-us/estateos-datenschutz"
                                        target="_blank"
                                        className="Contact__form__data_protection_check__link"
                                        rel="noreferrer"
                                    >
                                        Ich hab die Datenschutz gelesen und bin damit einverstanden. Die Zustimmung kann
                                        jederzeit widerrufen werden.
                                    </a>
                                </div>
                                <input
                                    type="checkbox"
                                    name="isSpam"
                                    tabIndex="-1"
                                    autoComplete="off"
                                    className="Contact__form__honeypot"
                                    onChange={e => setIsSpam(true)}
                                />
                            </div>
                            <div className="Contact__form__form-field ">
                                <input
                                    type="submit"
                                    value={loading ? 'Bitte Warten...' : 'Senden'}
                                    data-wait="Bitte Warten..."
                                    className="Contact__button"
                                />
                            </div>
                        </form>
                    ) : (
                        <div
                            className={cc({
                                Contact__message: true,
                                'Contact__message--success': success,
                                'Contact__message--error': !success,
                            })}
                        >
                            <div>{infoMsg}</div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default Contact
