import './header-mobile.scss'

import { Link } from 'gatsby'
import * as React from 'react'

import logo from './../../../public/icons/home-icon.svg'
import LinkWithIcon from '../link-with-icon/link-with-icon'
import UserIcon from './../../images/icons/icon_login.svg'
import { IHeaderProps } from '../header/header'

export default class HeaderMobile extends React.Component<IHeaderProps> {
    public render() {
        return (
            <header className="Header">
                <nav role="navigation">
                    <Link to="/">
                        <img className="Header__logo" src={logo} />
                    </Link>
                    <div className="Menu__ToggleIcon">
                        <input type="checkbox" />
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul className="Menu">
                            {this.props.mainPages?.map(p => (
                                <li key={p.name}>
                                    <div className="Menu__Item">
                                        <Link
                                            activeStyle={{
                                                color: '#54b7b2',
                                            }}
                                            key={p.name}
                                            to={p.path ? p.path : '/' + p.full_slug}
                                        >
                                            {p.name}
                                        </Link>
                                    </div>
                                </li>
                            ))}
                            <li>
                                <div className="Menu__Item">
                                    <LinkWithIcon
                                        icon={UserIcon}
                                        link="https://app.estateos.com/auth/register"
                                        text="Login"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}
