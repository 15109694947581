import React from 'react'
import './logowall.scss'

interface LogowallProps {
    blok: {
        text: string
        logos: {
            name: string
            link: string
            image: { filename: string }
        }[]
    }
}

const Logowall = ({ blok }: LogowallProps) => (
    <div className="Logowall">
        <p>{blok.text}</p>

        <div className="Logowall__logos">
            {blok.logos.map(logo => (
                <div className="logo__container">
                    <img key={logo.name} className="Logowall__logo" src={logo.image.filename} />
                </div>
            ))}
        </div>
    </div>
)

export default Logowall
