import './imageteaserV2.scss'
import logo from './../../../public/icons/estateos_black.svg'
import * as React from 'react'
import { LinkBlok } from '../../types/linkBlok'

export interface IImageTeaserProps {
    blok: {
        headline: string
        text: string
        cta_text: string
        cta_link: LinkBlok
        avatar_image: { filename: string }
        avatar_name: string
        avatar_jobtitle: string
        asset: { filename: string }
        image_left: boolean
        youtube_video: LinkBlok
    }
}

export default class ImageTeaserV2 extends React.Component<IImageTeaserProps> {
    state = {
        image: 'https://a.storyblok.com/f/107237/1366x768/25069e45b7/home_teaser_5.png',
    }

    setImageTeaser = photoLink => {
        this.setState(state => ({ image: photoLink }))
    }

    public render() {
        const {
            headline,
            text,
            cta_text,
            cta_link,
            avatar_image,
            avatar_name,
            avatar_jobtitle,
            asset,
            image_left,
            youtube_video,
            icons,
        } = this.props.blok

        return (
            <div className="ImageTeaserV2">
                <div className="ImageTeaser__content">
                    <div className="ImageTeaser__title">{headline}</div>

                    <div className="ImageTeaser__text">{text}</div>

                    <div className="ImageTeaser__actions__wrapper">
                        {icons.map(icon => (
                            <div
                                className="ImageTeaser__action"
                                onClick={() => this.setImageTeaser(icon.image?.filename)}
                            >
                                <img className="ImageTeaser__action__logo" src={icon.icon?.filename} />
                                <div className="ImageTeaser__action__title">{icon.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <img className="ImageTeaser__asset" src={this.state.image} alt="" />
            </div>
        )
    }
}
