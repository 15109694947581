import './imageteaser-horizontal.scss'

import { navigate } from 'gatsby'
import * as React from 'react'

import Avatar from '../avatar/avatar'
import Button from '../button/button'
import YoutubeFrame from '../youtubeFrame/youtubeFrame'
import { LinkBlok } from '../../types/linkBlok'

export interface IImageTeaserProps {
    blok: {
        headline: string
        text: string
        cta_text: string
        cta_link: LinkBlok
        avatar_image: { filename: string }
        avatar_name: string
        avatar_jobtitle: string
        asset: { filename: string }
        image_left: boolean
        youtube_video: LinkBlok
        imagesBlock: any[]
    }
}

export default class ImageTeaserHorizontal extends React.Component<IImageTeaserProps> {
    public render() {
        const { headline, text, asset, imagesBlock, youtube_video } = this.props.blok

        return (
            <div className="ImageTeaserHorizontal">
                <div className="ImageTeaserHorizontal__content">
                    <div className="ImageTeaserHorizontal__title">{headline}</div>

                    <div className="ImageTeaserHorizontal__text">{text}</div>
                </div>

                {['jpg', 'png', 'jpeg'].includes(asset?.filename?.slice(-3)) && (
                    <img className="ImageTeaserHorizontal__asset" src={asset.filename} alt="" />
                )}

                {asset?.filename?.slice(-3) === 'mov' && (
                    <video loop muted autoPlay className="ImageTeaserHorizontal__asset" src={asset.filename} />
                )}
                {youtube_video?.cached_url && <YoutubeFrame link={youtube_video.cached_url} />}

                {imagesBlock && (
                    <div className="ImageBlock">
                        {imagesBlock?.map((image, i) => (
                            <img key={i} src={image?.image.filename} />
                        ))}
                    </div>
                )}
            </div>
        )
    }
}
