import './notice.scss'

import cc from 'classcat'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'

export interface INoticeProps {
    blok: {
        text: string
        component: string
    }
}

export default class Notice extends React.Component<INoticeProps> {
    public render() {
        const { text, component } = this.props.blok

        return (
            <div className={cc({ Notice: true, 'Notice--hint': component === 'hint' })}>
                <ReactMarkdown>{text}</ReactMarkdown>
            </div>
        )
    }
}
