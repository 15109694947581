import './teaser.scss'

import { Link } from 'gatsby'
import * as React from 'react'

export interface ITeaserProps {
    blok: {
        title: string
        text: string
        link: {
            cached_url: string
        }
    }
}

export default class Teaser extends React.Component<ITeaserProps> {
    public render() {
        const { title, text, link } = this.props.blok

        return (
            <Link to={'/' + link.cached_url} className="Teaser">
                <h2 className="Teaser__title">{title}</h2>
                <div className="Teaser__text">{text}</div>
            </Link>
        )
    }
}
