import './table-of-content-side-bar.scss'
import React from 'react'
import MenuIcon from '../../images/icons/menu.png'

const TableOfContentSideBar = ({ titles }: string[]) => {
    return (
        <div className="TableOfContentSideBar">
            <nav className="TableOfContentSideBar__nav">
                <ul>
                    <li>
                        <a className="TableOfContentSideBar__nav__header" href>
                            <img src={MenuIcon} alt="Menu Icon" />
                            &nbsp; INHALTSVERZEICHNIS
                        </a>
                    </li>
                    <ul>
                        {titles.map((title, index) => (
                            <li key={index}>
                                <a
                                    className="TableOfContentSideBar__nav__content"
                                    href={`#${title?.replace(/\s/g, '-')}`}
                                >
                                    {title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </ul>
            </nav>
        </div>
    )
}

export default TableOfContentSideBar
