import './header.scss'

import { Link } from 'gatsby'
import * as React from 'react'
import { withBreakpoints } from 'gatsby-plugin-breakpoints'

import logo from './../../../public/icons/estatos_white.svg'
import Button from '../button/button'
import LinkWithIcon from '../link-with-icon/link-with-icon'
import UserIcon from './../../images/icons/icon_login.svg'
import HeaderMobile from '../header-mobile/header-mobile'

export interface IHeaderProps {
    mainPages: {
        full_slug: string
        slug: string
        name: string
        path: string
    }[]
}

class Header extends React.Component<IHeaderProps> {
    public render() {
        const { breakpoints } = this.props
        return breakpoints.xs ? (
            <HeaderMobile mainPages={this.props.mainPages} />
        ) : (
            <div className="BackgroundImage">
                <header className="Header">
                    <Link to="/">
                        <img className="Header__logo" src={logo} />
                    </Link>

                    <div className="Header__links">
                        {this.props.mainPages?.map(p => (
                            <Link key={p.name} to={p.path ? p.path : '/' + p.full_slug}>
                                {p.name}
                            </Link>
                        ))}
                    </div>

                    <div className="Header__actions">
                        {/*<LinkWithIcon icon={UserIcon} link="https://app.estateos.com/auth/register" text="Login" />*/}

                        <a
                            className="Header__action"
                            href="https://app.estateos.com/auth/register"
                            target="_blank"
                            rel="noreferrer"
                        >
                            App
                        </a>
                    </div>
                </header>
                <div className="Header__background__title center">We make buildings better places to live</div>
                <div className="Header__background__address bottom-right">
                    *Automatisiertes 3-Zimmer-Apartment im Gleispark Berlin
                </div>
            </div>
        )
    }
}
export default withBreakpoints(Header)
