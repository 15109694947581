const myCustomQueries = {
    xs: '(max-width: 1000px)',
    sm: '(max-width: 720px)',
    md: '(max-width: 1024px)',
    l: '(max-width: 1536px)',
    iconsXl: '(max-width: 2000px)',
    iconsF: '(max-width: 1490px)',
    iconsM: '(max-width: 1240px)',
    iconsS: '(max-width: 750px)',
    iconsXS: '(max-width: 640px)',
    iconsXXS: '(max-width: 500px)',
    iconsXXXS: '(max-width: 300px)',

    portrait: '(orientation: portrait)',
}

module.exports = {
    siteMetadata: {
        title: `estateos.com`,
        description: ``,
        author: `@saijim`,
    },
    flags: {
        PRESERVE_WEBPACK_CACHE: false,
        FAST_DEV: false,
    },
    plugins: [
        `gatsby-plugin-react-helmet`,
        {
            resolve: 'gatsby-plugin-breakpoints',
            options: { queries: myCustomQueries },
        },
        {
            resolve: `gatsby-source-filesystem`,
            options: {
                name: `images`,
                path: `${__dirname}/src/images`,
            },
        },
        `gatsby-plugin-image`,
        `gatsby-plugin-sharp`,
        `gatsby-transformer-sharp`,
        `gatsby-plugin-typescript`,
        `gatsby-plugin-sass`,
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `gatsby-starter-default`,
                short_name: `starter`,
                start_url: `/`,
                background_color: `#663399`,
                theme_color: `#663399`,
                display: `minimal-ui`,
                icon: `src/images/logo.svg`, // This path is relative to the root of the site.
            },
        },
        {
            resolve: 'gatsby-source-storyblok',
            options: {
                accessToken: '6ctF3eXKnRFTperXGVkVSAtt',
                version: 'draft',
                resolveRelations: [''],
                includeLinks: true,
            },
        },
    ],
}
