import React from 'react'
import './article-card.scss'
import ArrowRightIcon from './../../images/arrow_right.svg'
import { Link } from 'gatsby'

export interface ArticleCardProps {
    image: { filename: string }
    title: string
    createdAt: string
    shortDescription: string
    link_text?: string
    link_target?: string
}

const ArticleCard = ({ image, title, createdAt, shortDescription, link_text, link_target }: ArticleCardProps) => (
    <div className="ArticleCard">
        <Link to={'/' + link_target}>
            <img className="Card__image" src={image.filename} alt="" />
        </Link>
        <div className="Card__content">
            <h3 className="Card__content__name">{title}</h3>
            <p className=" Card__content__createdAt"> {createdAt}</p>
            <p>{shortDescription}</p>
            <div className="Card__usecases">
                {link_target && link_text && (
                    <Link to={'/' + link_target} className="Card__usecases__usecase__link">
                        {link_text} &nbsp; <img className="Card__usecases__usecase__link__arrow" src={ArrowRightIcon} />
                    </Link>
                )}
            </div>
        </div>
    </div>
)

export default ArticleCard
