import './testimony.scss'
import React from 'react'
import TestimonyCard, { TestimonyCardProps } from '../testimonycard/testimonyCard'

interface TestimonyShowcaseProps {
    blok: {
        title: string
        testimonies: TestimonyCardProps[]
    }
}

const Testimony = ({ blok }: TestimonyShowcaseProps) => {
    return (
        <div className="TestimonyShowcase">
            <div className="Showcase__title-wrap">
                <h3 className="Showcase__second_title">{blok.title}</h3>
            </div>

            <div className="TestimonyShowcase__container">
                {blok.testimonies.map(card => (
                    <TestimonyCard key={card.name} {...card}></TestimonyCard>
                ))}
            </div>
        </div>
    )
}

export default Testimony
