import './page.scss'

import cc from 'classcat'
import React from 'react'
import SbEditable from 'storyblok-react'

import DynamicComponent from '../DynamicComponent'
import Layout from '../layout/layout'
import PageTree from '../pagetree/pagetree'
import TableOfContentSideBar from '../table-of-content-side-bar/table-of-content-side-bar'

interface IPage {
    blok: any
    documentationPages: {
        full_slug: string
        slug: string
        name: string
    }[]
    mainPages: {
        full_slug: string
        slug: string
        name: string
    }[]
    name: string
    intro: string
}

const Page = ({ blok, documentationPages, mainPages }: IPage) => {
    const content = blok.body && blok.body.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} />)
    const titles = blok.body && blok.body.map((blok: any) => blok.title)

    return (
        <SbEditable content={blok}>
            <Layout mainPages={mainPages}>
                <div className={cc({ Page: true, 'Page--withSidebar': blok.component === 'documentation-page' })}>
                    {blok.component === 'documentation-page' && (
                        <div className="Page__sidebar">
                            <PageTree pages={documentationPages} />
                        </div>
                    )}

                    <div className="Page__documentation">
                        <p>{blok?.intro}</p>

                        {content}
                    </div>
                    {blok.component === 'documentation-page' && <TableOfContentSideBar titles={titles} />}
                </div>
            </Layout>
        </SbEditable>
    )
}

export default Page
