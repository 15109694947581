import './link-with-icon.scss'

import React from 'react'

interface LinkWithIconProps {
    link: string
    icon: string
    text: string
}

const LinkWithIcon = ({ link, icon, text }: LinkWithIconProps) => {
    return (
        <a className="LinkWithIcon" href={link} target="_blank" rel="noreferrer">
            <img src={icon} />

            {text}
        </a>
    )
}

export default LinkWithIcon
