import './grid.scss'

import * as React from 'react'

import DynamicComponent from '../DynamicComponent'

export interface IGridProps {
    blok: {
        columns: number
        blocks: any
    }
}

export default class Grid extends React.Component<IGridProps> {
    public render() {
        const { columns, blocks } = this.props.blok
        return (
            <div className="Grid" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
                {blocks.map((block, i) => (
                    <DynamicComponent key={i} blok={block} />
                ))}
            </div>
        )
    }
}
