import './statistics.scss'

import React from 'react'

import ArrowToOne from '../../images/icons/arrow_to_1.svg'
import ArrowToTwo from '../../images/icons/arrow_to_2.svg'
import ArrowToOneMobile from '../../images/icons/arrow_reverse_down.svg'
import ArrowToTwoMobile from '../../images/icons/arrow_down.svg'

interface StatisticsProps {
    blok: {
        subtitle: string
        title: string
    }
}

const Statistics = ({ blok }: StatisticsProps) => {
    return (
        <div className="Showcase">
            <div className="Showcase__title-wrap">
                <p className="Showcase__title">{blok.subtitle}</p>

                <h3 className="Showcase__second_title">{blok.title}</h3>
            </div>

            <div className="Statistics__content">
                <div className="Statistics__content__circle-wrap">
                    <div className="Statistics__content__circle">
                        <h4 className="Statistics__content__circle__counter">5000</h4>

                        <div className="Statistics__content__circle__title">AKTIVE NUTZER</div>
                    </div>

                    <img src={ArrowToOne} className="Statistics__content__arrow Statistics__content__arrow--pc" />
                    <img
                        src={ArrowToOneMobile}
                        className="Statistics__content__arrow Statistics__content__arrow--mobile"
                    />

                    <div className="Statistics__content__circle">
                        <h4 className="Statistics__content__circle__counter">30</h4>

                        <div className="Statistics__content__circle__title">OBJEKTE</div>
                    </div>

                    <img className="Statistics__content__arrow Statistics__content__arrow--pc" src={ArrowToTwo} />
                    <img
                        className="Statistics__content__arrow Statistics__content__arrow--mobile"
                        src={ArrowToTwoMobile}
                    />

                    <div className="Statistics__content__circle">
                        <h4 className="Statistics__content__circle__counter">25</h4>

                        <div className="Statistics__content__circle__title">INTEGRATIONEN</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics
